<template>
  <v-app
    :class="[{'nav-drawer-mini': menuIsVerticalNavMini}, {'content-full': appContentWidth === 'full'}]"
    class="content-layout vertical-nav"
  >
    <v-navigation-drawer
      v-if="!menuIsMenuHidden"
      ref="layoutDrawer"
      v-model="isVerticalNavMenuActive"
      app
      :right="$vuetify.rtl"
      :mini-variant="menuIsVerticalNavMini"
      :mini-variant-width="miniNavigation.width"
      :width="navigation.width"
      style="z-index: 6; overflow: visible"
      class="app-navigation-menu elevation-5"
    > <!-- 
      :expand-on-hover="menuIsVerticalNavMini"
      :mini-variant="menuIsVerticalNavMini"
      -->
      <v-slide-x-transition>
        <div
          v-if="isVerticalNavMenuActive"
          class="d-flex align-center mini-button"
          :style="miniButtonStyle"
          @click.stop="$vuetify.breakpoint.mdAndDown ? isVerticalNavMenuActive = false : menuIsVerticalNavMini = !menuIsVerticalNavMini"
        >
          <v-btn
            min-height="0px"
            min-width="0px"
            height="20px"
            width="20px"
            color="primary"
            fab
          >
            <span class="material-icons-round font-size-14">
              {{ menuIsVerticalNavMini ? 'chevron_right' : 'chevron_left' }}
            </span>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <vertical-nav-menu
        :nav-menu-items="navMenuItems"
        :menu-is-vertical-nav-mini.sync="menuIsVerticalNavMini"
        @close-nav-menu="isVerticalNavMenuActive = false"
      />
    </v-navigation-drawer>

    <div :style="{
        'position': 'fixed',
        'height': messageData.height,
        'width': '100%',
        'background-color': '#EB7B26',
        'padding': '0px 5px',
        'color': 'black',
        'font-weight': '600',
        'visibility': messageData.visibility,
        'display': 'flex',
        'justify-content': 'center',
        'z-index': '5',
      }"
    >
      <v-spacer/>
      <div style="align-content: center;">
        <label>
          {{ i18n.t(messageData.text) }}
        </label>
      </div>
      <v-spacer/>
      <div>
        <v-btn
          text
          icon
          style="height: 20px !important; width: 20px !important; background-color: black !important; margin: 5px;"
          @click="messageDataOriginal.visibility = 'hidden'"
        >
          <span class="material-symbols-rounded font-size-13 white-color font-weight-bold">
            close
          </span>
        </v-btn>
      </div>
    </div>

    <!-- TODO: All elevations are to bottom and footer requires shadow on top -->
    <v-app-bar
      v-if="appBarType !== 'hidden'"
      ref="refAppBar"
      app
      :absolute="appBarType === 'static'"
      flat
      :elevate-on-scroll="appBarType !== 'static'"
      :elevation="appBarType !== 'static' ? $vuetify.theme.isDark ? 4: 3 : 0"
      class="mx-auto app-bar-static"
      style="display: grid !important; align-items: center;"
      :style="{
        'height': $vuetify.breakpoint.xsOnly ? '175px' : $vuetify.breakpoint.mdAndDown ? '120px' : '82px',
        'margin-top': messageData.visibility === 'hidden' ? '0px' : messageData.height,
      }"
      :class="[{ 'app-bar-shinked': appBarType === 'fixed' && scrollY }, { 'bg-blur': appBarIsBlurred && appBarType === 'fixed' && scrollY }]"
    >
      <slot
        name="navbar"
        :isVerticalNavMenuActive="isVerticalNavMenuActive"
        :toggleVerticalNavMenuActive="toggleVerticalNavMenuActive"
      ></slot>
    </v-app-bar>

    <slot name="v-app-content"></slot>

    <v-main :style="{
      'padding-left': (isVerticalNavMenuActive && !$vuetify.breakpoint.mdAndDown) ? menuIsVerticalNavMini ? '80px' : '300px' : '0px',
      'padding-top': $vuetify.breakpoint.xsOnly ? '175px': $vuetify.breakpoint.mdAndDown ? '120px': '82px',
      'padding-right': '0px',
      'padding-botom': isInEditor ? '0px' : '56px',
      'margin-top': messageData.visibility === 'hidden' ? '0px' : messageData.height,
    }">
      <app-content-container>
        <page-header v-if="!isInEditor" />
        <slot></slot>
      </app-content-container>
    </v-main>
    <v-overlay
      :value="$store.state.app.shallContentShowOverlay"
      z-index="4"
      absolute
      class="content-overlay"
    ></v-overlay>

    <v-footer
      v-if="footerType !== 'hidden' && !isInEditor"
      app
      inset
      :absolute="footerType === 'static'"
      padless
      :class="{'mx-auto': appContentWidth !== 'full'}"
      :color="footerType === 'static' ? 'transparent' : null"
    >
      <div
        :class="{'px-5': footerType === 'fixed'}"
        class="py-4 w-full"
      >
        <slot name="footer"></slot>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref, computed, watch } from 'vue'
import AppContentContainer from '@core/layouts/components/app-content-container/AppContentContainer.vue'
import { getVuetify } from '@/@core/utils'
import useAppConfig from '@core/@app-config/useAppConfig'
import VerticalNavMenu from '@core/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import { useWindowScroll } from '@vueuse/core'

import PageHeader from "lib-hermes-components/src/lib-components/page-header/page-header.vue";
import { i18n, } from '@/plugins/i18n';
import LibClientManagementRs from "lib-client-management-rs/src/lib-components/client-management-rs.vue";

export default {
  components: {
    AppContentContainer,
    VerticalNavMenu,
    
    PageHeader,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
    isInEditor: {
      type: Boolean,
      required: true,
    }
  },
  methods: {

    setBorderWidth() {
      let i = this.$refs.layoutDrawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      //i.style.cursor = "ew-resize";
      i.style.backgroundColor = "red";
    },
    
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.layoutDrawer.$el
      const drawerBorder = el.querySelector(".v-navigation-drawer__border")
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left"

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      /*drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      )

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          //this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      )*/
    }

  },
  mounted() {
    this.setBorderWidth()
    this.setEvents()
  },
  setup() {
    /* eslint-disable object-curly-newline, operator-linebreak */
    const { menuIsVerticalNavMini, menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth } =
      useAppConfig()
    const $vuetify = getVuetify()

    const isVerticalNavMenuActive = ref(true)

    // TODO: Check do we need below watch
    watch(
      () => $vuetify?.breakpoint.mdAndDown,
      value => {
        isVerticalNavMenuActive.value = !value
      },
      {
        immediate: true,
      },
    )

    const toggleVerticalNavMenuActive = () => {
      isVerticalNavMenuActive.value = !isVerticalNavMenuActive.value
    }

    const { y: scrollY } = useWindowScroll()

    const navigation = ref({
      shown: false,
      width: 300,
      borderSize: 5,
    });

    const miniNavigation = ref({
      width: 80,
    });

    const currentNavigation = computed(() => {
      if (!menuIsVerticalNavMini.value) {
        return navigation.value;
      }
      else {
        return miniNavigation.value;
      }
    })

    const miniButtonStyle = computed(() => {
      return {
        'margin-left': (currentNavigation.value.width-10) + 'px',
        'margin-top': '31px',
        'position': 'absolute',
        'z-index': '7'
      }
    })

    // Message data
    const messageDataOriginal = ref({
      visibility: 'hidden',
      height: '30px',
      text: 'GemsysMaintenanceMessage',
    });
    const messageData = computed(() => {
      return {
        visibility: messageDataOriginal.value.visibility,
        height: $vuetify.breakpoint.mdAndDown ? '70px' : '30px',
        text: messageDataOriginal.value.text,
      }
    });

    // Check if the message should be shown
    const localRealm = "gemsys-dev.geminys.com";
    var realm = window.location.hostname;
    if (realm === 'localhost') {
      realm = localRealm;
    }
    LibClientManagementRs.methods.getClient(null, realm).then((response => {
      var clientName = response?.data?.name;
      LibClientManagementRs.methods.getPropertyValue(clientName, 'hermes', 'has-app-maintenance-message-active').then((response) => {
        if (response?.data === true) {
          messageDataOriginal.value.visibility = 'visible';
        }
      });
    }));

    return {
      i18n,
      isVerticalNavMenuActive,
      toggleVerticalNavMenuActive,
      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
      scrollY,

      navigation,
      miniNavigation,
      currentNavigation,
      miniButtonStyle,
      messageDataOriginal,
      messageData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/layouts/styles/_variables';

.app-content-container {
  padding: $content-padding-vertical-navigation-menu;
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

// Vuetify Fix
// https://github.com/vuetifyjs/vuetify/issues/13327
$nav-drawer-mini-width: 85px;

.v-application {
  .v-main,
  .v-footer {
    transition-duration: 0.3s;
  }

  .v-footer {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @include theme(v-footer) using ($material) {
    // Elevation 3 with -y
    &.v-footer--fixed {
      box-shadow: 0 -4px 8px -4px rgba(map-deep-get($material, 'shadow-color'), 0.42) !important;
    }
  }

  &.nav-drawer-mini {
    .v-main {
      // We haven't used `ltr` & `rtl` mixin because those doesn't work with top-level selectors: https://github.com/vuetifyjs/vuetify/issues/13987
      @at-root {
        .nav-drawer-mini {
          &.v-application--is-ltr {
            .v-main {
              padding-left: $nav-drawer-mini-width !important;
            }
          }
          &.v-application--is-rtl {
            .v-main {
              padding-right: $nav-drawer-mini-width !important;
            }
          }
        }
      }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      .app-navigation-menu ~ .v-footer,
      .app-navigation-menu + .v-app-bar {
        left: $nav-drawer-mini-width !important;
      }
    }
  }

  .v-app-bar,
  .v-footer {
    max-width: calc(2000px - (1.5rem * 2));
    @media screen and (max-width: 2000px) {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }

    @at-root .v-application {
      &.content-full {
        .v-footer,
        .v-app-bar {
          max-width: unset;
          margin-left: $content-padding-vertical-navigation-menu !important;
          margin-right: $content-padding-vertical-navigation-menu !important;
        }
      }

      &:not(.nav-drawer-mini) {
        @media screen and (max-width: 1711px) {
          .app-navigation-menu ~ .v-footer,
          .app-navigation-menu + .v-app-bar {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
          }
        }
      }
      &.nav-drawer-mini {
        @media screen and (max-width: 1523px) {
          .v-footer,
          .v-app-bar {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
          }
        }
      }
    }
  }

  .v-app-bar {
    border-radius: 0 0 0px 0px !important;
    z-index: 5 !important;

    &.v-toolbar:not(.app-bar-shinked) {
      background-color: transparent;
    }

    &.app-bar-static {
      will-change: padding, background-color;
      transition: padding 0.2s ease, background-color 0.18s ease, left 0.3s ease;

      &.v-toolbar.v-sheet:not(.v-app-bar--is-scrolled) {
        box-shadow: none !important;
        z-index: 5;
      }

      :deep(.v-toolbar__content) {
        padding: 0;
      }
    }
  }
}

@include theme(v-app-bar) using ($material) {
  &.v-toolbar.app-bar-shinked {
    background-color: map-deep-get($material, 'cards');
  }
}

.v-application.content-layout {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .v-main,
    .v-footer,
    .v-app-bar {
      max-width: unset;
      left: 0 !important;
      @include ltr() {
        padding-left: 0 !important;
      }
      @include rtl() {
        padding-right: 0 !important;
      }
    }
  }
}
.mini-button {
  transition: all 0.18s ease-in-out;
}
</style>

<template>
  <div class="vertical-nav-menu-container" @mouseenter="isMouseHovered = true" @mouseleave="isMouseHovered = false">
    <slot name="v-nav-menu-header">
      <vertical-nav-menu-header @close-nav-menu="$emit('close-nav-menu')"></vertical-nav-menu-header>
    </slot>

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <perfect-scrollbar
      class="ps-nav-menu-items ps-4 pe-4"
      :options="perfectScrollbarOptions"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <v-list expand class="vertical-nav-menu-items py-0">
        <component
          :is="resolveNavItemComponent(item)"
          v-for="item in navMenuItems"
          :key="item.subheader || item.title"
          :item="item"
        />
        <div v-show="!menuIsVerticalNavMini">
          <div v-if="route.meta.layout === 'content' || route.meta.layout === 'editor'">
            <v-divider />
            <Search v-if="perms.includes('hermes-search')" />
            <v-divider v-if="perms.includes('hermes-search')" />
            <DocumentTree v-if="perms.includes('hermes-folder-document-view')" />
            <FavoriteTree v-if="perms.includes('hermes-folder-document-view')" />
            <SubscriptionTree v-if="perms.includes('hermes-subscription')" />
            <RecycleBinTree v-if="perms.includes('hermes-folder-document-delete')" />
          </div>
          <div v-if="route.meta.layout === 'settings'">
            <SettingsMenu />
          </div>
        </div>
        <div v-show="menuIsVerticalNavMini">
          <v-divider />
          <v-list class="pa-0" expand>
            <v-list-item-group>
              <div v-for="(item, index) in miniItems" :key="index">
                <v-list-group
                  :value="item.active"
                  class="menu-group vertical-nav-menu-group mini-menu-group"
                  :class="{ 'v-list-group--active': item.active }"
                >
                  <template #prependIcon>
                    <router-link v-if="item.isRouterLink" :to="item.to" @click="handleButtonClick(item)">
                      <div class="fill-div">
                        <span class="material-icons-round">
                          {{ icons[item.type] }}
                        </span>
                      </div>
                    </router-link>
                    <div v-else class="fill-div" @click="handleButtonClick(item)">
                      <span class="material-icons-round" :style="item.active ? '' : 'color: #E0E0E0 !important'">
                        {{ icons[item.type] }}
                      </span>
                    </div>
                  </template>
                </v-list-group>
                <v-divider />
              </div>
            </v-list-item-group>
          </v-list>
        </div>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed, provide } from 'vue'

import { useRouter } from '@core/utils'

// SFCs
import VerticalNavMenuHeader from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import VerticalNavMenuSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
import VerticalNavMenuGroup from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composable
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// Menu type
import { DocumentTree, FavoriteTree, RecycleBinTree, SubscriptionTree } from 'lib-document-management-vue/src/lib-components'
import SettingsMenu from '@/components/settings/settings-menu.vue'

// Search
import Search from 'lib-search-vue/src/lib-components/search-vue.vue'

import Authentication from 'lib-security/src/lib-components/security.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink,
    VerticalNavMenuSectionTitle,

    // 3rd Party
    PerfectScrollbar,

    // Menus
    DocumentTree,
    FavoriteTree,
    SubscriptionTree,
    RecycleBinTree,
    SettingsMenu,

    Search,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
    menuIsVerticalNavMini: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { resolveNavItemComponent, isMouseHovered } = useVerticalNavMenu()
    const { route } = useRouter()
    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('openGroups', ref([]))

    const root = ref('root')

    const perms = ref([])
    const role = ref('')

    const miniItems = computed(() => {
      var array = []
      if (route.value.meta.layout === 'content' || route.value.meta.layout === 'editor') {
        array.push({
          type: 'folder',
          isRouterLink: true,
          opensMenu: false,
          to: {
            name: 'folder-view',
            params: { id: root.value },
          },
          active: route.value.name === 'folder-view' || route.value.name === 'document-view',
        })
        // Favorites not needed in mini menu because it can not be opened:
        array.push({
          type: 'Favorites',
          isRouterLink: false,
          opensMenu: true,
          to: {
            name: '',
            params: {},
          },
          active: false,
        })
        if (perms.value.includes('hermes-folder-document-delete')) {
          array.push({
            type: 'RecycleBin',
            isRouterLink: true,
            opensMenu: false,
            to: {
              name: 'recycle-bin-view',
              params: { id: root.value },
            },
            group: 'recycle-bin-view',
            active: route.value.name === 'recycle-bin-view',
          })
        }
      } else if (route.value.meta.layout === 'settings') {
        array = [
          {
            type: 'admin',
            isRouterLink: false,
            opensMenu: true,
            to: {
              name: 'settings',
              params: {},
            },
          },
          {
            type: 'support',
            isRouterLink: false,
            opensMenu: true,
            to: {
              name: 'settings',
              params: {},
            },
          },
        ]
      }
      return array
    })

    function getPermissions() {
      try {
        var tokenParsed = Authentication.methods.getTokenParsed()
        if (!tokenParsed) {
          return
        }
        perms.value = tokenParsed.realm_access.roles
        role.value = tokenParsed.role
      } catch (error) {
        console.log(error)
      }
    }
    getPermissions()

    const icons = ref({
      admin: 'settings',
      support: 'menu_book',
      folder: 'folder',
      Shortcuts: 'shortcut',
      Favorites: 'star',
      RecycleBin: 'delete',
    })

    function handleButtonClick(item) {
      if (item.opensMenu) {
        emit('update:menu-is-vertical-nav-mini', false)
      }
    }

    return {
      route,
      resolveNavItemComponent,
      perfectScrollbarOptions,
      isMouseHovered,
      shallShadowBottom,

      miniItems,
      perms,
      role,
      icons,
      handleButtonClick,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.app-navigation-menu {
  transition-duration: 0.4s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
  will-change: box-shadow, transform !important;

  .v-list-item,
  .v-subheader {
    padding: 0 18px 0 22px;
  }

  .v-list-item {
    margin-top: 0.375rem;
  }

  /*.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px;
  }*/

  .v-navigation-drawer__border {
    background-color: transparent !important;
  }

  // &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
  //   @include elevation(9);
  //   @include elevationTransition();
  // }
}

@include app-elevation('app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover', 9);

// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 82px) !important;
  }

  // menu scroll shadow
  .shadow-bottom {
    display: none;
    position: absolute;
    z-index: 2;
    height: 50px;
    width: 100%;
    left: -6px;
    pointer-events: none;
    margin-top: -0.7rem;
    filter: blur(5px);
  }
}

@include theme--child(vertical-nav-menu-container) using ($material) {
  .shadow-bottom {
    background: linear-gradient(
      map-deep-get($material, 'background') 40%,
      rgba(map-deep-get($material, 'background'), 0.1) 95%,
      rgba(map-deep-get($material, 'background'), 0.05)
    );
    margin-top: -0.5rem;
    filter: blur(4px);
  }
}

@include theme(vertical-nav-menu-items) using ($material) {
  @include ltr() {
    padding-right: 0px !important;
  }
  @include rtl() {
    padding-left: 0px !important;
  }
  /*@at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(.v-navigation-drawer--is-mouseover) {
    .vertical-nav-menu-items {
      @include ltr() {
        padding-right: 5px !important;
      }
      @include rtl() {
        padding-left: 5px !important;
      }
    }
  }*/

  .v-subheader:not(:first-child) {
    margin-top: 18px;
  }

  .v-list-item__icon {
    &:first-child {
      @include ltr() {
        margin: 0 0 0 0; // margin: 0 12px 0 0;
      }
      @include rtl() {
        margin: 0 0 0 0; // margin: 0 0 0 12px;
      }
      align-self: center;
    }
    &.v-list-group__header__append-icon {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }

  // Center align small icon
  .v-list-group__items .v-list-item__icon {
    margin-top: 0px;
    @include ltr() {
      margin-left: 12px;
    }
    @include rtl() {
      margin-right: 12px;
    }
  }

  // Center align small icon
  .v-list-group .v-list-item__icon {
    margin-top: 0px;
    @include ltr() {
      margin-left: 0; // 12px
    }
    @include rtl() {
      margin-right: 0; // 12px
    }
  }

  .v-list-item {
    @include app-states($material);

    height: 44px;
    min-height: 44px;
    color: map-deep-get($material, 'text', 'primary');
    // ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
    justify-content: flex-start !important;

    &.v-list-item--active {
      &:not(.v-list-group__header) {
        color: white;
        // background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
      }
      &.v-list-group__header {
        background-color: rgba(
          map-deep-get($material, 'text', 'primary'),
          map-deep-get($material, 'states', 'selected')
        );
      }
    }

    &.vertical-nav-menu-link {
      .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
